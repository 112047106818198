













































import {
  Component,
  Vue
} from 'vue-property-decorator'
import { isWeiXin, isIOS, isAndroid } from '@/utils/Device'
import { Track } from '@/utils/Sensor'
import { KEWLApp } from '@/js/kewl'

  @Component({
    components: {
    },
    computed: {
    }
  })
export default class Index extends Vue {
    public showWeixinMask = false

    public os = isIOS ? 'ios' : isAndroid ? 'android' : ''

    public download (action: string) {
      console.log(action)
      Track(action)

      if (isWeiXin()) {
        this.showWeixinMask = true
        return
      }
      KEWLApp.downloadApp()
    }

    public mounted () {
      document.title = this.$t('share.title') as any
    }
}
