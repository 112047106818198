// 神策埋点
export const Track = function(page_action: string) {
    /* eslint-disable */
    let params = {
        'url': window.location.href.substr(0, 80), // STRING
        'page_action': page_action || '', // STRING
    };
    console.log(params);
    // 神策埋点
    (window as any).KEWLSensor.register('kewlweb_pg_share', params);
    (window as any).KEWLSensor.track(params);
    /* eslint-enable */
}